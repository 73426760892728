import { DatePicker } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker';
import dayjs, { Dayjs } from 'dayjs';
import { assign, isArray, isFunction } from 'lodash';
import { angularize } from 'react-in-angularjs';

interface DateTimeRange {
  fromTo: {
    from?: string;
    to?: string;
  };
}

type DateTimeRangePickerProps = {
  defaultFromDate: string;
  defaultToDate: string;
  onChange: (fromTo: DateTimeRange) => void;
}

const DATE_VALUE_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
const DATE_DISPLAY_FORMAT = 'DD-MM-YYYY HH:mm';

const DateTimeRangePicker = ({ defaultFromDate, defaultToDate, onChange } : DateTimeRangePickerProps) => {
  const defaultValue = getDefaultValue(defaultFromDate, defaultToDate);

  const onSelect = (value: RangePickerProps['value']) => {
    if (isFunction(onChange)) {
      const fromTo = {
        from: undefined,
        to: undefined
      };

      if (isArray(value)) {
        assign(fromTo, {
          from: value[0]?.format(DATE_VALUE_FORMAT),
          to: value[1]?.format(DATE_VALUE_FORMAT)
        });
      }

      onChange({ fromTo });
    }
  };

  return (
    <DatePicker.RangePicker
      showTime={{ format: 'HH:mm' }}
      format={DATE_DISPLAY_FORMAT}
      allowEmpty={[true, true]}
      allowClear={true}
      showNow={true}
      onChange={onSelect}
      defaultValue={defaultValue}
    />
  );
};

function getDefaultValue(defaultFromDate: string, defaultToDate: string): [Dayjs | undefined, Dayjs | undefined] {
  return [
    getDate(defaultFromDate),
    getDate(defaultToDate)
  ];
}

function getDate(value: string) : Dayjs | undefined {
  if (!value) {
    return undefined;
  }

  const parsedValue = dayjs(value);
  return dayjs(parsedValue, DATE_VALUE_FORMAT);
}

angularize(DateTimeRangePicker, 'dateTimeRangePicker', angular.module('uasApp'), {
  defaultFromDate: '<',
  defaultToDate: '<',
  onChange: '&'
});
